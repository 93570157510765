export const omniApps = [
  {
    icon_class: 'omni:app:attribution',
    url: '/apps/an_attribution',
    name: 'Attribution',
  },
  {
    icon_class: 'omni:brand:gwi',
    url: '/apps/an_construct_gwiae',
    name: 'Audience Explorer (GWI)',
  },
  {
    icon_class: 'omni:brand:kantar',
    url: '/apps/an_construct_kantarae',
    name: 'Audience Explorer (Kantar)',
  },
  {
    icon_class: 'omni:app:audienceExplorer',
    url: '/apps/lotame_dmp',
    name: 'Audience Explorer (Lotame)',
  },
  {
    icon_class: 'omni:app:audienceExplorer',
    url: '/apps/agg_knowledge',
    name: 'Audience Explorer (Neustar)',
  },
  {
    icon_class: 'omni:app:audienceExplorer',
    url: '/apps/audience_builder',
    name: 'Audience Explorer (Omni)',
  },
  {
    icon_class: 'omni:app:audienceExplorer',
    url: '/apps/audience_placeiq',
    name: 'Audience Explorer (PlaceIQ)',
  },
  {
    icon_class: 'omni:brand:google',
    url: '/apps/google_if',
    name: 'Audience Explorer (Google)',
  },
  {
    icon_class: 'omni:brand:royMorgan',
    url: '/apps/an_construct_roymorganae',
    name: 'Audience Explorer (Roy Morgan)',
  },
  {
    icon_class: 'omni:app:audienceExplorer',
    url: '/apps/semasio',
    name: 'Audience Explorer (Semasio)',
  },
  {
    icon_class: 'omni:brand:telmar',
    url: '/apps/an_construct_telmarae',
    name: 'Audience Explorer (Telmar)',
  },
  {
    icon_class: 'omni:app:channelPlanner',
    url: 'https://app.climatepartner.com/',
    name: 'Carbon Calculator',
  },
  {
    icon_class: 'omni:app:channelPlanner',
    url: '/apps/omg_channel_planning',
    name: 'Channel Planner',
  },
  {
    icon_class: 'omni:app:contentAutomation',
    url: '/apps/adzu',
    name: 'Content Automation',
  },
  {
    icon_class: 'omni:app:contentAutomation',
    url: '/apps/adylic',
    name: 'Content Engine',
  },
  {
    icon_class: 'omni:app:contentAutomation',
    url: '/apps/content_inspirationv2',
    name: 'Content Inspiration',
  },
  {
    icon_class: 'omni:brand:dalle',
    url: 'https://www.bing.com/images/create?FORM=GENILP',
    name: 'Dalle',
  },
  {
    icon_class: 'omni:app:digitalContent',
    url: '/apps/accuen_inventory',
    name: 'Digital Content',
  },
  {
    icon_class: 'omni:brand:doubleVerify',
    url: 'https://pinnacle.doubleverify.com/login',
    name: 'Double Verify',
  },
  {
    icon_class: 'omni:app:flowchartBuilder',
    url: 'https://portalapp-flowchart-prod-eus.grayfield-559fd3e5.eastus.azurecontainerapps.io',
    name: 'Flowchart Builder',
  },
  {
    icon_class: 'omni:brand:getty',
    url: 'https://www.gettyimages.com/ai/generation/about',
    name: 'Getty Images',
  },
  {
    icon_class: 'omni:app:investmentPlanner',
    url: '/apps/omg_investment_planning',
    name: 'Investment Planner',
  },
  {
    icon_class: 'omni:object:plan',
    url: '/apps/an_icp',
    name: 'Investment and Channel Planner',
  },
  {
    icon_class: 'omni:app:mediaOps',
    url: 'https://mediaops.annalect.com/',
    name: 'Media Ops Hub',
  },
  {
    icon_class: 'omni:app:performanceReporting',
    url: '/apps/ande_empowerment_pr',
    name: 'Performance Reporting',
  },
  {
    icon_class: 'omni:app:audienceExplorer',
    url: '/apps/professional_audiences',
    name: 'Professional Audiences',
  },
  {
    icon_class: 'omni:object:report',
    url: '/apps/an_report_builder',
    name: 'Reporting Hub',
  },
  {
    icon_class: 'omni:brand:shutterstock',
    url: 'https://enterprise.shutterstock.com/ai-image-generator',
    name: 'Shutterstock',
  },
  {
    icon_class: 'omni:app:tvContent',
    url: '/apps/videoamp_new',
    name: 'Video Content',
  },
  {
    icon_class: 'omni:app:tacticalWorkflow',
    url: '/apps/omg_planit',
    name: 'Planit',
  },
  {
    icon_class: 'omni:app:taxonomyBuilder',
    url: '/apps/omg_taxonomy',
    name: 'Taxonomy Builder',
  },
  {
    icon_class: 'omni:brand:tradedesk',
    url: 'https://auth.thetradedesk.com/Account/Login?',
    name: 'Trade Desk',
  },
  {
    icon_class: 'omni:app:qCulturalInsights',
    url: '/apps/sh_culture_q',
    name: 'Culture Q Insights',
  },
  {
    icon_class: 'omni:app:digitalContent',
    url: 'https://www.accuenplatform.com/backstage/api/advertiser',
    name: 'Digital Activation',
  },
  {
    icon_class: 'omni:app:digitalContent',
    url: 'https://www.accuenplatform.com/smp/publisher/search',
    name: 'Digital Operations',
  },
  {
    icon_class: 'omni:app:performanceReporting',
    url: 'https://onewp.okta.com/home/onewp_omcomnilotame_1/0oab4osazkBB0r7yW357/alnb4oyz8ouE7aAga357?fromHome=true',
    name: 'Marketplace Buyer',
  },
  {
    icon_class: 'omni:app:performanceReporting',
    url: 'https://omdmarketingsciences.shinyapps.io/PDX20/',
    name: 'Purchase Dynamix',
  },
  {
    icon_class: 'omni:app:audienceExplorer',
    url: 'https://accounts.teads.tv/oauth/saml/omni?redirectUrl=https://ad-manager.teads.tv/data-management/external-custom-audiences-management',
    name: 'Audience Explorer (Teads)',
  },
  {
    icon_class: 'omni:app:creo',
    url: 'https://www.omgcreo.com/',
    name: 'OMG Creo',
  },
];
